// import { getUserInfoByTicket } from '@/views/carWash/api';
import { getRequest } from '@/utils/api'
import { setToken } from '@/utils/auth'
import { Toast } from 'vant'
interface User {
  userId: any;
  userName: any;
  name: any;
  dicts: any;
  deptId: any;
  token?: any;
  ticket: any;
  guide: any;
  userInfo: any
}
const state: User = {
  userId: null,
  userName: null,
  name: null,
  dicts: null,
  deptId: null,
  ticket: null,
  guide: null,
  userInfo: null
}
const mutations = {
  SET_USER_ID: (state: User, userId: string): void => {
    state.userId = userId
  },
  SET_USER_NAME: (state: User, userName: string): void => {
    state.userName = userName
  },
  SET_NAME: (state: User, name: string): void => {
    state.name = name
  },
  SET_DICTS: (state: User, dicts: any): void => {
    state.dicts = dicts
  },
  SET_DEPTID: (state: User, deptId: any): void => {
    state.deptId = deptId
  },
  SET_GUIDE: (state: User, guide: any): void => {
    state.guide = guide
  },
  SET_USERINFO: (state: User, userInfo: any): void => {
    state.userInfo = userInfo
  }
}
const actions = {
  async setUserInfo({ commit }: any, info: any): Promise<void> {
    console.log('info===', info)
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 30000
    })
    const resp = await getRequest(`/pems/system/auth/getWsxyLoginUser`, {
      appKey: info.appKey,
      ticket: info.ticket
    })
    console.log('userInfo', resp)
    Toast.clear()
    if (resp.data.statusCode === 200) {
      setToken(resp.data.iscUserAccount, 'userName')
      setToken(resp.data.Authorization, 'Authorization')
      setToken(resp.data.iscUserId, 'IscUserId')
      commit('SET_USERINFO', resp.data)
      return
    }
    Toast.fail('登录失败')
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
