import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // 个人请假管理
  {
    path: '/personal/index',
    name: 'personal/index',
    component: () => import('@/views/personal/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/personal/addFrom',
    name: 'personal/addFrom',
    component: () => import('@/views/personal/addForm.vue')
  },
  // 请假管理审核
  {
    path: '/audit/index',
    name: 'audit/index',
    component: () => import('@/views/audit/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/audit/auditFrom',
    name: 'audit/auditFrom',
    component: () => import('@/views/audit/auditForm.vue')
  },
  {
    path: '/audit/auditDetail',
    name: 'audit/auditDetail',
    component: () => import('@/views/audit/auditDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to): any {
    return { y: 0 }
  }
})

export default router
