const state = {
  searchHistory: JSON.parse(localStorage.getItem('searchHistory')) || []
}

// actions
const actions = {
//   setLanguageData({ commit }) {
//     this.$api.getLanguage().then(res => {
//       commit('setLanguageData', res.result)
//     })
//   }
}

// mutations
const mutations = {
  setSearchHistory(data, info, key) {
    data.searchHistory = info
    localStorage.setItem('searchHistory', JSON.stringify(info))
  }
}

const getters = {
  searchHistory: state => { return state.searchHistory }
}

export default {
  state,
  actions,
  mutations,
  getters
}
