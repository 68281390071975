import { getToken } from '@/utils/auth'
const getters = {
  userId: (state: any): string => state.user.userId || getToken('userId'),
  userName: (state: any): string => state.user.userName || getToken('userName'),
  name: (state: any): string => state.user.name || getToken('name'),
  deptId: (state: any): string => state.user.deptId || getToken('deptId'),
  dicts: (state: any): string => state.user.dicts || JSON.parse(getToken('dicts')),
  guide: (state: any): string => state.user.guide || getToken('guide') ? JSON.parse(getToken('guide')) : false,
  userInfo: (state: any): string => state.user.userInfo && state.user.userInfo.iscUserId ? state.user.userInfo : getToken('userInfo') ? JSON.parse(getToken('userInfo')) : null
}

export default getters
