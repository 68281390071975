import axios from 'axios'
import { getToken, removeToken } from '@/utils/auth'
import { Toast } from 'vant'
axios.interceptors.request.use((config) => {
  const token = getToken('Authorization')
  if (token) {
    config.headers.Authorization = token
    config.headers.iscUserId = getToken('IscUserId')
    config.timeout = 30000
  }
  return config
})
// 响应拦截器
axios.interceptors.response.use(
  (success) => {
    if (success.status && success.data.status === 500) {
      return
    }
    if (success.status === 201) {
      // 文件下载成功状态码
      return success
    }
    return success
  },
  (error) => {
    if (error.response.status === 504 || error.response.status === 503 || error.response.status === 404) {
      Toast('服务器找不到请求的资源')
    } else if (error.response.status === 403) {
      Toast('权限不足，请联系管理员')
    } else if (error.response.status === 401) {
      Toast('用户验证失败')
      removeToken('Authorization')
    } else {
      if (error.response.data && error.response.data.msg) {
        Toast(error.response.data.msg)
      }
    }
    return {
      data: {
        status: error.response.status,
        message: 'fail'
      }
    }
  }
)
// 全局变量：用于后期 URL 的统一处理，例如添加请求前缀等
// const base = 'http://11.141.215.240:18080'
const base = ''
// 定义 post 请求：key/value方式传参，例如security只支持此种方式传参
export const postKeyValueRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${base}${url}?`, // 此处不是单引号，是1前面的符号
    data: params,
    transformRequest: [function(data) { // 将参数转化为 key/value 形式
      let ret = ''
      for (const i in data) {
        ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
      }
      return ret
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${base}${url}`,
    data: params
  })
}
export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${base}${url}`,
    data: params
  })
}
export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${base}${url}`,
    params: params
  })
}
export const getRequestFullUrl = (url, params) => {
  return axios({
    method: 'get',
    url: `${url}`,
    params: params
  })
}
export const deleteRequest = (url, params) => {
  return axios({
    method: 'delete',
    url: `${base}${url}`,
    params: params
  })
}

export const exportRequest = (url, params) => {
  return axios({
    headers: { 'content-type': 'application/octet-stream;charset=UTF-8' },
    method: 'post',
    url: `${base}${url}`,
    params: params,
    responseType: 'blob'
  })
}
export const importRequest = (url, params) => {
  return axios({
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    url: `${base}${url}`,
    data: params
  })
}
export const exportExcelRequest = (url, params) => {
  return new Promise((resolve, reject) => {
    exportRequest(url, params).then(response => {
      resolve(response.data)
      const blob = new Blob([response.data], {
        type: 'application/vnd.ms-excel'
      })
      const fileName = decodeURI(
        response.headers['content-disposition'].split('filename=')[1]
      )
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob) // 创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName// 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href) // 释放掉blob对象
    }, (err) => {
      reject(err)
    })
  })
}
