import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.less'
import './rem.ts'
// import request from '@/utils/request'
import dayjs from 'dayjs'
import LicensePlate from 'vue-license-plate'
import 'vue-license-plate/lib/licensePlate.css'
// import eruda from 'eruda'
// eruda.init()
const AdvancedFormat = require('dayjs/plugin/weekOfYear')
Vue.use(LicensePlate)
dayjs.extend(AdvancedFormat)
Vue.use(Vant)
Vue.config.productionTip = false

Vue.prototype.$dayjs = dayjs

// Vue.prototype.$request = request

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

app.$mount('#app')

export default app

