const TokenKey = 'Authorization'

export function getToken(tokenKey?: string): string {
  if (!tokenKey) {
    tokenKey = TokenKey
  }
  return localStorage.getItem(tokenKey) || ''
}

export function setToken(token: string, tokenKey?: string): void {
  if (!tokenKey) {
    tokenKey = TokenKey
  }
  return localStorage.setItem(tokenKey, token)
}

export function removeToken(tokenKey?: string): void {
  if (!tokenKey) {
    tokenKey = TokenKey
  }
  return localStorage.setItem(tokenKey, '')
}
